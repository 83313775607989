import {Link, useParams} from "react-router-dom";
import {DeliverySpot, OrderType, Shop} from "../types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import Loading from "../../Loading";
import FancyCheckbox from "../FancyCheckbox";
import ButtonConfirm from "../settings/ButtonConfirm";
import LineNumberedText from "../generate-qr/LineNumberedText";
import EditDeliverySpotModal from "./EditDeliverySpotModal";
import {usePnpLocales} from "../hooks/usePnpLocales";

interface Params {
  shopId: string | undefined
}

interface State {
  spots: DeliverySpot[],
  creating: boolean,
  multiline: boolean
}

const DeliverySpots = () => {
  const {locales, getLocaleOrDefault} = usePnpLocales();
  const {shopId} = useParams<Params>();
  const [loading, setLoading] = useState<boolean>(true);
  const [shop, setShop] = useState<Shop | null>(null);
  const [state, setState] = useState<State>({spots: [], creating: false, multiline: false});
  const [newSpot, setNewSpot] = useState<DeliverySpot>({
    id: 0,
    name: "",
    orderType: "OrderApp",
    area: '',
    enabled: true,
    i18n: {
      translations: []
    },
    shopId: shopId ? parseInt(shopId) : 0
  });
  const {t, locale} = useTranslation();
  const orderTypeOptions = [
    {value: 'OrderAppTakeAway', label: t(`pick-n-pay.settings.orderTypes.TakeAway`)},
    {value: 'OrderApp', label: t(`pick-n-pay.settings.orderTypes.DineInPlace`)}
  ];
  
  useEffect(() => {
    if (shopId) {
      const promises = [
      fetch(`/api/pick-n-pay/shops/${shopId}`)
        .then(response => response.json())
        .then((data: any) => {
          setShop(data as Shop)
        }),
      fetch(`/api/pick-n-pay/shops/${shopId}/delivery-spots`)
        .then(response => response.json())
        .then((data: DeliverySpot[]) => {
          setState({...state, spots: data});
        })
      ];
      
      Promise.all(promises).then(() => {
        console.log("Loaded shop and delivery spots");
        setLoading(false);
      });
    }
  }, [shopId])

  const addSpot = (e: any) => {
    e.preventDefault();
    setState({...state, creating: true});
    
    const body = []
    if(state.multiline){
     const names = newSpot.name.split('\n');
      names.forEach(name=>{
        body.push({
          id: 0,
          name: name.replace("\t"," "),
          orderType: newSpot.orderType,
          area: '',
          enabled: true,
          i18n: {
            translations: []
          },
          shopId: shopId? parseInt(shopId) : 0
        });
      });
    } else {
      body.push(newSpot)  
    }
    
    fetch(`api/pick-n-pay/shops/${shopId}/delivery-spots`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(body),
    }).then(response => response.json())
      .then((data: DeliverySpot|DeliverySpot[]) => {
        if(state.multiline){
          const arr = data as DeliverySpot[];
          setState({...state, spots: [...arr,...state.spots], creating: false, multiline: false});
        }else {
          const created = data as DeliverySpot;
          setState({...state, spots: [created,...state.spots], creating: false});
        }
        setNewSpot({
          id: 0,
          name: "",
          orderType: newSpot.orderType,
          area: '',
          enabled: true,
          i18n: {
            translations: []
          },
          shopId: shopId? parseInt(shopId) : 0
        });
      });
  };
  
  const updateSpot = (spot: DeliverySpot) => {
    fetch(`api/pick-n-pay/shops/${shopId}/delivery-spots/${spot.id}`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: JSON.stringify(spot),
    }).then(()=>{
      setState({...state, spots: state.spots.map(s => s.id === spot.id ? spot : s)});
    })
  }
  
  const deleteSpot = (spot:DeliverySpot)=>{
    fetch(`api/pick-n-pay/shops/${shopId}/delivery-spots/${spot.id}`, {
      method: 'DELETE'
    }).then(()=>{
      setState({...state, spots: state.spots.filter(s=>s.id !== spot.id)});
    })
  }
  
  const deleteAllSpots = ()=>{
    fetch(`api/pick-n-pay/shops/${shopId}/delivery-spots`, {
      method: 'DELETE',
      body: JSON.stringify(state.spots.map(s=>s.id)),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(()=>{
      setState({...state, spots: []});
    })
  }
  
  if(loading)
    return (<Loading visible={loading} />);

  return (
    <div className="pick-n-pay delivery-spots">
      <h1>{t('pick-n-pay.delivery-spots.header')} - {shop?.description}</h1>
      <Link to={`/pick-n-pay/shops/${shopId}`}>{t('pick-n-pay.delivery-spots.back-to-shop')}</Link>
      <p>
        {t('pick-n-pay.delivery-spots.description')}
      </p>
      <form onSubmit={addSpot}>
        <fieldset className="card-section">
          <legend>{t('pick-n-pay.delivery-spots.new.legend')}</legend>
          <div className={"card-group"}>
            <FancyCheckbox checked={state.multiline} name={"multiline"} label={t('pick-n-pay.delivery-spots.new.multiline')}
              onChange={(e) => setState({...state, multiline: e.target.checked})}
            />

            <div className={"input-group"}>
              <label className={"form-label input-group-text"}>{t('pick-n-pay.delivery-spots.new.name.label')}</label>
              {!state.multiline && (
              <input className={"form-control"} type={"text"} value={newSpot.name ?? ""} onChange={(e) => setNewSpot({...newSpot, name: e.target.value})}/>
              )}
              {state.multiline && (
                <LineNumberedText value={newSpot.name ??""} onChange={(e)=>setNewSpot({...newSpot, name:e.target.value})} />
              )}
              <Select name={"orderType"}
                      defaultValue={orderTypeOptions.find(o => o.value === newSpot.orderType)}
                      className={"form-control"}
                      options={orderTypeOptions}
                      onChange={(o: { value: string }) => setNewSpot({...newSpot, orderType: o.value as OrderType})}/>
              <button className={"btn btn-add"} type={"submit"}>{t('pick-n-pay.delivery-spots.new.create')}</button>
              <Loading visible={state.creating}/>
              <p className={"form-info"}>{t('pick-n-pay.delivery-spots.new.name.description')}</p>
            </div>
          </div>
        </fieldset>
      </form>
      {state.spots.length === 0 && (
        <div className={"alert"}>
          <span>{t('pick-n-pay.delivery-spots.no-spots-found')}</span>
        </div>
      )}
      {state.spots.length > 0 && (
        <>
          <ButtonConfirm className={"btn btn-delete"} onConfirm={deleteAllSpots}>{t('pick-n-pay.delivery-spots.new.remove-all')}</ButtonConfirm>
          <div className={"delivery-spot-result search-result-container"}>
           
            <div className={"search-result-header"}>
              <span>{t('pick-n-pay.delivery-spots.result.name')}</span>
              <span>{t('pick-n-pay.delivery-spots.result.area')}</span>
              <span>{t('pick-n-pay.delivery-spots.result.order-type')}</span>
              <span>{t('pick-n-pay.delivery-spots.result.actions')}</span>
            </div>
            <ol className={"search-result"}>
              {state.spots.map(spot => (
                <li key={spot.id} className={spot.enabled?"enabled":"disabled"}>
                  <span className={"spot-name"}>{spot.name}</span>
                  <span className={"spot-area"}>{spot.area}</span>
                  <span className={"spot-order-type"}>
                    {spot.orderType === "OrderApp" ? t(`pick-n-pay.settings.orderTypes.DineInPlace`) : t(`pick-n-pay.settings.orderTypes.TakeAway`)}
                  </span>
                  <div>
                    <div className={"btn-group"}>
                      <FancyCheckbox checked={spot.enabled} name={`enabled-${spot.id}`} label={spot.enabled?"Disable":"Enable"} onChange={(e)=>updateSpot({...spot, enabled:e.target.checked})} />
                      <ButtonConfirm className={"btn btn-delete"} onConfirm={()=>deleteSpot(spot)}>{t('pick-n-pay.delivery-spots.result.delete')}</ButtonConfirm>
                      <EditDeliverySpotModal locales={locales} spot={spot} onSave={updateSpot} />
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </>
      
      )}
    </div>
  )
}
export default DeliverySpots;